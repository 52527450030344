import { Button, MenuItem, TextField } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React from "react";
import { Link } from "react-router-dom";

class AddCalendarEventPage extends React.Component {
  render() {
    return (
      <div className="container mt-2">
        <h1>Add Calendar Event</h1>
        <div className="d-flex flex-column gap-2">
          <TextField label="Event Name" fullWidth />
          <DateTimePicker label="Start Date" />
          <DateTimePicker label="End Date" />
          <TextField label="Location" fullWidth />
          <TextField label="URL" fullWidth />
          <TextField label="Visibility" select fullWidth>
            <MenuItem value="public">Feature on homepage when not logged in</MenuItem>
            <MenuItem value="private">Do not feature on homepage when not logged in</MenuItem>
          </TextField>
          <CKEditor
            editor={ClassicEditor}
            data=""
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
          <div className="d-flex justify-content-end gap-2">
            <Button variant="contained" component={Link} to="/calendar">Cancel</Button>
            <Button variant="contained">Save</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddCalendarEventPage;