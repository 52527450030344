import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  if (!localStorage.getItem("jwt")) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children as React.ReactElement;
};