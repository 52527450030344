import { useEffect, useState } from "react";
import { callAPI } from "../../../utils/API";
import { Link } from "react-router-dom";

interface DynamicPage {
  id: number;
  title: string;
  contents: string;
  slug: string;
}

export default function ManageDynamicPages() {
  const [pages, setPages] = useState<DynamicPage[]>([]);

  useEffect(() => {
    const fetchPages = async () => {
      const result = await callAPI("pages");
      setPages(result);
    };

    fetchPages();
  }, []);

  return (
    <div className="container mt-2">
      <h1>Dynamic Pages</h1>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {pages.map((page) => (
          <div className="col" key={page.id}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{page.title}</h5>
                <p className="card-text">{page.contents}</p>
                <button className="btn btn-primary">Edit</button>
              </div>
            </div>
          </div>
        ))}
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">New Page</h5>
              <p className="card-text">Create a new dynamic page.</p>
              <Link to="/admin/pages/create" className="btn btn-primary">Create</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
