import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // import useParams
import { callAPI } from "../../../utils/API";
import { Alert, Button } from "@mui/material";
import { TROOP_DOMAIN } from "../../../utils/config";
import DOMPurify from "dompurify";

interface MeritBadge {
  id: string;
  name: string;
  img: string;
  eagle_required: 0 | 1;
  requirements: string;
  workbook_url: string;
}

function MeritBadgeDetailsPage() {
  const [meritBadge, setMeritBadge] = useState<MeritBadge | null>(null);
  const [error, setError] = useState("");
  const { id } = useParams<{ id: string }>(); // initialize useParams

  useEffect(() => {
    callAPI(`meritBadges`)
      .then((result) => {
        setMeritBadge(result.find((mb: MeritBadge) => mb.id === id) || null);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [id]);

  if (error) {
    return (
      <div className="container mt-2">
        <h1>Merit Badge Details</h1>
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  if (!meritBadge) {
    return (
      <div className="container mt-2">
        <h1>Merit Badge Details</h1>
        <p>Loading...</p>
      </div>
    );
  }

  const sanitizedHTML = DOMPurify.sanitize(meritBadge.requirements, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  });

  return (
    <div className="container mt-2">
      <h1>{meritBadge.name}</h1>
      <img src={`https://${TROOP_DOMAIN}/${meritBadge.img}`} alt={meritBadge.name} height="200" width="200" />
      <Button variant="contained" color="primary" href={meritBadge.workbook_url} target="_blank" rel="noreferrer">
        Workbook
      </Button>
      <p>Eagle Required: {meritBadge.eagle_required ? "Yes" : "No"}</p>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  );
}

export default MeritBadgeDetailsPage;