import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { callAPI } from "../../utils/API";
import NotFoundPage from "../ui/NotFoundPage";

interface Page {
  title: string;
  contents: string;
}

export default function DynamicPage() {
  const { slug } = useParams<{ slug: string }>();
  const [page, setPage] = useState<Page | null>(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    async function getPage() {
      try {
        const response = await callAPI(`/public/page/${slug}`);
        setPage(response);
        if (!response) {
          setNotFound(true);
        }
      } catch (error) {
        setNotFound(true);
      }
    }

    getPage();
  }, [slug]);

  if (notFound) {
    return <NotFoundPage />;
  }

  return (
    <div className="container mt-2">
      {page ? (
        <>
          <h1>{page.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: page.contents }} />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}