import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useState } from "react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import { callAPI } from "../../utils/API";
import { useNavigate } from "react-router-dom";

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const result = await callAPI("calendar");
      setEvents(result);
    };

    fetchEvents();
  }, []);

  return (
    <div className="container mt-2">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        weekends={false}
        events={events.map((event: any) => {
          return {
            id: event.id,
            title: event.summary,
            start: event.start,
            end: event.end,
          };
        })}
        dateClick={(info) => {
          navigate(`/calendar/events/add?date=${info.dateStr}`);
        }}
        eventClick={(info) => {
          navigate(`/calendar/events/${info.event.id}`);
        }}
      // eventContent={renderEventContent}
      />
    </div>
  );
};

export default CalendarPage;