import React from "react";

class AnnouncementsWidget extends React.Component {
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h1>Announcements</h1>
        </div>
      </div>
    );
  }
}

export default AnnouncementsWidget;