import { Component } from "react";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { callAPI } from "../../utils/API";
import { TROOP_NAME, TROOP_DOMAIN, API_URL } from "../../utils/config";
import {
  Avatar,
  Button,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Typography
} from "@mui/material";

interface LoginPageState {
  username: string;
  password: string;
  rememberMe: boolean;
  error: string;
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href={`https://${TROOP_DOMAIN}/`}>
        {TROOP_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class LoginPage extends Component<{}, LoginPageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      username: "",
      password: "",
      rememberMe: false,
      error: "",
    };
  }

  componentDidMount() {
    // Automatic login - /pickup-day/<base64 encoded username:password>
    const url = window.location.href;
    const pickupIndex = url.indexOf("pickup-day/");
    if (pickupIndex !== -1) {
      try {
        const encoded = url.substring(pickupIndex + 11);
        const decoded = atob(encoded);
        const split = decoded.split(":");
        if (split.length === 2) {
          this.setState({
            username: split[0],
            password: split[1],
          }, () => {
            localStorage.setItem("driver-ui-enabled", "true");
            window.history.replaceState({}, document.title, "/");
            this.onSubmit();
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  onSubmit() {
    callAPI("tree-tags/log-in/", "POST", {
      username: this.state.username,
      password: this.state.password,
    })
      .then((data: any) => {
        if (data.authToken) {
          localStorage.setItem("jwt", data.authToken);
          // localStorage.setItem("role", data.role);
          // localStorage.setItem("scout_id", data.scout_id);
          // localStorage.setItem("user_id", (data.scout_id - 1000).toString());
          window.location.href = "/";
        } else {
          this.setState({ error: data.error });
        }
      })
      .catch((error) => {
        this.setState({ error: "Login rejected" });
      });
  }

  onGoogleLogin(credential?: string) {
    if (!credential) {
      this.setState({ error: "Google login failed" });
      return;
    }
    callAPI("/auth/google", "POST", {
      token: credential,
    })
      .then((data: any) => {
        if (data.authToken) {
          localStorage.setItem("jwt", data.authToken);
          // localStorage.setItem("role", data.role);
          // localStorage.setItem("scout_id", data.scout_id);
          // localStorage.setItem("user_id", (data.scout_id - 1000).toString());
          window.location.href = "/";
        } else {
          this.setState({ error: data.error });
        }
      })
      .catch((error) => {
        this.setState({ error: "Login rejected" });
      });
  }

  render() {
    return (
      <div>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: "url(/assets/img/tyler.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  this.onSubmit();
                }}
                sx={{ mt: 1, textAlign: "center" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => {
                    window.location.href = API_URL + "auth/google";
                  }}
                >
                  Sign In with Google
                </Button>
                {this.state.error && (
                  <Typography color="error" className="mt-2">{this.state.error}</Typography>
                )}
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default LoginPage;
