import React from "react";

class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="container mt-2">
        <h1 className="display-1">404</h1>
        <h2>Page Not Found</h2>
      </div>
    );
  }
}

export default NotFoundPage;