import React from "react";
import { Link } from "react-router-dom";

const resources = [
  {
    name: "Dynamic Pages",
    permalink: "/admin/pages",
    description: "Edit content on dynamic pages.",
  },
];

class AdminPage extends React.Component {
  render() {
    return (
      <div className="container mt-2">
        <h1>Administration</h1>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {resources.map((resource) => (
            <div className="col" key={resource.name}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{resource.name}</h5>
                  <p className="card-text">{resource.description}</p>
                  <Link to={resource.permalink} className="btn btn-primary">Manage</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default AdminPage;