import React from "react";
import { Link } from "react-router-dom";
import { TROOP_NAME } from "../../utils/config";
import "./NavBar.css";
import { getUserInfo } from "../../utils/API";
import { googleLogout } from "@react-oauth/google";

const mainMenuPrivate = [
  {
    name: "Dashboard",
    link: "/",
    for: "all",
  },
  {
    name: "Announcements",
    link: "/announcements",
    for: "all",
  },
  {
    name: "Calendar",
    link: "/calendar",
    for: "all",
  },
  {
    name: "Resources",
    link: "/resources",
    for: "all",
  },
];

const mainMenuPublic = [
  {
    name: "About Us",
    link: "/pages/about",
  },
  {
    name: "Tree Tags",
    link: "/pages/tree-tags",
  },
  {
    name: "Contact",
    link: "/contact",
  },
  {
    name: "Eagle Scouts",
    link: "/pages/eagle-scouts",
  },
];

const role = "admin"; // TODO: fix this

export default function NavBar() {
  const userInfo = getUserInfo();
  const [currentPage, setCurrentPage] = React.useState(window.location.pathname);
  const atTop = false;
  if (!userInfo) {
    return (
      <header className="p-3 public-navbar" style={{ backgroundColor: atTop ? "transparent" : "#000" }}>
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <Link to="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
              {TROOP_NAME}
            </Link>

            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              {mainMenuPublic.map((menuItem) => {
                return (
                  <li key={menuItem.name}>
                    <Link
                      to={menuItem.link}
                      className="nav-link px-2 text-white"
                      onClick={() => {
                        setCurrentPage(menuItem.link);
                      }}
                    >
                      {menuItem.name}
                    </Link>
                  </li>
                );
              })}
            </ul>

            <div className="text-end">
              <Link className="btn btn-outline-light me-2" to="/login">Login</Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
  return (
    <header className="py-2 border-bottom no-print">
      <div className="container-fluid d-grid gap-3 align-items-center custom-grid-template-columns">
        <div className="dropdown">
          <Link
            to="/"
            className="d-flex align-items-center col-lg-4 mb-lg-0 text-decoration-none dropdown-toggle link-light"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bi bi-tree-fill fs-4 mb-2 mt-0"></i>
            <h1 className="fs-5 ms-1">{TROOP_NAME}</h1>
          </Link>
          <ul className="dropdown-menu text-small shadow">
            {mainMenuPrivate.map((menuItem) => {
              if (role !== menuItem.for && role !== "admin") {
                return null;
              }
              return (
                <li key={menuItem.name}>
                  <Link
                    to={menuItem.link}
                    className="dropdown-item"
                    onClick={() => {
                      setCurrentPage(menuItem.link);
                    }}
                  >
                    {menuItem.name}
                  </Link>
                </li>
              );
            })}
            <li className="dropdown-divider"></li>
            <li>
              <a
                href={"https://tree-tags.troop600.com"}
                rel="noreferrer"
                target="_blank"
                className="dropdown-item"
              >
                Tree Tags
              </a>
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-center">
          <form className="w-100 me-3" role="search">
            <input
              type="search"
              className="form-control"
              placeholder="Search..."
              aria-label="Search"
              style={{ opacity: 0 }}
            />
          </form>
          <div className="flex-shrink-0 dropdown">
            <Link
              to="/account"
              className="d-block text-decoration-none dropdown-toggle link-light"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={userInfo?.picture || "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}
                alt="mdo"
                width="26"
                height="26"
                className="rounded-circle"
              />
            </Link>
            <ul className="dropdown-menu text-small shadow">
              <li>
                <Link className="dropdown-item" to="/account">
                  Account
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/admin">
                  Administration
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/settings">
                  Settings
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    localStorage.removeItem("jwt");
                    googleLogout();
                    window.location.href = "/";
                  }}
                >
                  Sign out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header >
  );
}