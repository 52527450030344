import "./App.css";
import MainPage from "./components/ui/MainPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Toaster } from "react-hot-toast";
import * as serviceWorker from "./serviceWorkerRegistration";
import { Component } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

interface AppState {
  newVersionAvailable: boolean;
  waitingWorker: any;
  loggedIn: boolean;
  theme: "light" | "dark";
}

interface AppProps { }

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const preferredTheme = prefersDarkMode ? "dark" : "light";
    let savedTheme = localStorage.getItem("theme");
    if (savedTheme !== "light" && savedTheme !== "dark") {
      savedTheme = null;
    }
    this.state = {
      newVersionAvailable: false,
      waitingWorker: {},
      loggedIn: !!localStorage.getItem("jwt") &&
        localStorage.getItem("jwt") !== "undefined",
      theme: savedTheme || preferredTheme,
    };
  }

  onServiceWorkerUpdate = (registration: any) => {
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    this.setState({ newVersionAvailable: false });
    window.location.reload();
  };

  componentDidMount = () => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });
    }
    this.updateTheme(this.state.theme);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      localStorage.setItem("jwt", token);
      window.history.replaceState({}, document.title, "/");
      this.setState({ loggedIn: true });
    }
  };

  render() {
    const theme = createTheme({
      palette: {
        mode: this.state.theme,
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={"App" + (this.state.theme === "dark" ? " App-dark" : "")}>
            <Toaster
              toastOptions={{
                style: {
                  background: this.state.theme === "dark" ? "#f0f0f0" : '#363636',
                  color: this.state.theme === "dark" ? '#000' : '#fff',
                },
                duration: 6000,
                success: {
                  duration: 3000,
                },
              }}
            />
            <MainPage
            // role={localStorage.getItem("role") || "scout"}
            // newVersionAvailable={this.state.newVersionAvailable}
            // onSWUpdateRequest={this.updateServiceWorker}
            // theme={this.state.theme}
            // onThemeUpdate={(theme: "light" | "dark") => {
            //   this.updateTheme(theme);
            // }}
            />
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    );
  }

  updateTheme(newTheme: "light" | "dark") {
    this.setState({ theme: newTheme });
    localStorage.setItem("theme", newTheme);
    const app = document.querySelector("body");
    if (app) {
      if (newTheme === "dark") {
        app.classList.add("App-dark");
      } else {
        app.classList.remove("App-dark");
      }
    }
  }
}

export default App;
