import React from "react";
import { Link } from "react-router-dom";

const resources = [
  {
    name: "Troop Roster",
    permalink: "/resources/roster",
    description: "Looking for someone's email? Forgot the name of someone in your patrol? Find it here.",
  },
  {
    name: "Merit Badges",
    permalink: "/resources/merit-badges",
    description: "There are 137 merit badges to choose from. Search and explore the list, view requirements, and download worksheets here.",
  },
  {
    name: "Library",
    permalink: "/resources/library",
    description: "Our troop has access to many merit badge booklets that can help you work through your partials and get requirements signed off.",
  },
  {
    name: "Troop Gear",
    permalink: "/resources/gear",
    description: "Don't have a tent? Need a water filter? Check out the inventory of troop gear to see if you can check out an item for an upcoming outing.",
  },
  {
    name: "Photos",
    permalink: "/resources/photos",
    description: "Curious what sorts of outings scouts have done in the past? Check out our collection of photos and relive some of our troop memories!",
  },
  {
    name: "Videos",
    permalink: "/resources/videos",
    description: "Check out a few videos produced by scouts in the troop!",
  },
  {
    name: "Scoutbook",
    permalink: "https://scoutbook.scouting.org/",
    external: true,
    description: "Log in to your scoutbook account to view your rank progress, merit badges, and more.",
  },
  {
    name: "Help",
    permalink: "/resources/help",
    description: "Need help learning how to use this site? Check out the help articles."
  },
  {
    name: "Documents",
    permalink: "/resources/documents",
    description: "View uploaded documents."
  },
  {
    name: "Eagle Project History",
    permalink: "/resources/eagle-project-history",
    description: "Stuck on what you might do for an Eagle Project? Check out some of the past projects in our troop, or add helpful information about yours."
  },
];

class ResourcesPage extends React.Component {
  render() {
    return (
      <div className="container mt-2">
        <h1>Resources</h1>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {resources.map((resource) => (
            <div className="col" key={resource.name}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{resource.name}</h5>
                  <p className="card-text">{resource.description}</p>
                  {resource.external ?
                    <a href={resource.permalink} className="btn btn-primary" target="_blank" rel="noreferrer">View</a> :
                    <Link to={resource.permalink} className="btn btn-primary">View</Link>
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ResourcesPage;