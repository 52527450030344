import { Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { callAPI } from "../../utils/API";
import DOMPurify from 'dompurify';
import { useParams } from "react-router-dom";

interface CalendarEvent {
  id: number;
  summary: string;
  description: string;
  location: string;
  start: string;
  end: string;
  signup_enabled: 0 | 1;
}

function CalendarEventPage() {
  const { id } = useParams();
  const [event, setEvent] = useState<CalendarEvent | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    callAPI(`calendar/${id}`)
      .then((response) => {
        setEvent(response.data);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  }, [id]);

  if (error) {
    return (
      <div className="container mt-2">
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="container mt-2">
        <p>Loading...</p>
      </div>
    );
  }

  const starts = new Date(event.start);
  const ends = new Date(event.end);
  const sanitizedHTML = DOMPurify.sanitize(event.description, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  });

  return (
    <div className="container mt-2">
      <h1>{event.summary}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      <p>{event.location}</p>
      <p>{starts.toLocaleString()} - {ends.toLocaleString()}</p>
      {event.signup_enabled === 1 ? (
        <div>
          <Button variant="contained">Sign Up</Button>
        </div>
      ) : null}
    </div>
  );
}

export default CalendarEventPage;