import { Component } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

interface SettingsProps {
  theme: "light" | "dark";
  onThemeUpdate: (theme: "light" | "dark") => void;
}

interface SettingsState { }

class SettingsPage extends Component<SettingsProps, SettingsState> {
  constructor(props: SettingsProps) {
    super(props);
    this.state = {};
  }
  render() {
    const isPWA = window.matchMedia("(display-mode: standalone)").matches;
    return <div className="container mt-2 mb-5">
      <h1>Settings</h1>
      {!isPWA && (
        <div className="alert alert-info">
          <h3>Install as App</h3>
          <p className="text-muted mb-1">Install this app for the best experience.</p>
          <Link className="btn btn-outline-primary" to="/install">Install Instructions</Link>
        </div>
      )}
      <h3>Theme</h3>
      <div className="bg-light p-2 rounded">
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
          }}
        >
          {this.props.theme} mode
          <IconButton sx={{ ml: 1 }} onClick={() => {
            this.props.onThemeUpdate(this.props.theme === "light" ? "dark" : "light");
          }} color="inherit">
            {this.props.theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>
      </div>
      <hr />
      <p className="text-muted text-center mt-3 mb-0">troopOS (Build <pre className="d-inline">{"GIT_HASH".substring(0, 7)}</pre>)</p>
    </div>;
  }
}

export default SettingsPage;
