import { useState, useEffect } from "react";
import { callAPI } from "../../utils/API";
import { Alert } from "@mui/material";
import DOMPurify from "dompurify";

interface Announcement {
  id: string;
  name: string;
  author: number;
  date: string;
  details: string;
  recipients: string;
}

function AnnouncementCard({ announcement }: { announcement: Announcement }) {
  const sanitizedHTML = DOMPurify.sanitize(announcement.details, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  });

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">{announcement.name}</h5>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </div >
  );
}

function AnnouncementsPage() {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    callAPI("announcements")
      .then((result) => {
        setAnnouncements(result);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  if (error) {
    return (
      <div className="container mt-2">
        <h1>Announcements</h1>
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  const sortedAnnouncements = announcements.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <div className="container mt-2">
      <h1>Announcements</h1>
      <div className="my-3 d-flex gap-2 flex-column">
        {sortedAnnouncements.map((announcement) => (
          <AnnouncementCard announcement={announcement} key={announcement.id} />
        ))}
      </div>
    </div>
  );
}

export default AnnouncementsPage;